.page {
    position: relative;
    z-index: 2;
    overflow: hidden;
    .extra-image{

        position: absolute;
        box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);

        &.right{
            top: 200px;
            width: 350px;
            right: 0;
            transform: translateX(150px); 
           
        }

        &.left{
            bottom: -35px;
            left: -100px; 
            width: 230px;
            @media(max-width: 1199px){
                left: -90px;
                width: 180px;
            }
        }
        
        @media(max-width: 991px) {
            display: none;
        }
    }

    > .container{
        position: relative;
        
    }

    .label {
        padding: 5px 15px;
        background: #F2F2F3;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 20px;
        letter-spacing: 1px;

    }

    .blocks {
        
        .block {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 120px 0 0;

            &:last-child{
                margin-bottom: 0!important;
            }

            .image {
                flex-basis: 60%;
                max-width: 60%;
                min-height: 400px;
                background-size: cover;
                background-position: 50% 50%;
                box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
                z-index: 2;
                position: relative;
                top: -40px;
                margin-right: -110px
            }

            .text {
                flex-basis: 60%;
                background: #fff;
                max-width: 60%;
                box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
                padding: 70px 120px 70px 180px;
                z-index: 1;
                position: relative;

                .label {
                    padding: 5px 15px;
                    background: #F2F2F3;
                    font-size: 13px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    letter-spacing: 1px;

                }

                h3 {
                    font-size: 30px;
                    line-height: 1.3;
                    font-weight: 600;
                    margin-bottom: 20px;
                }

                .preview {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 1.6;
                    margin-bottom: 20px;
                }

                .button {
                    margin-bottom: 20px;
                }
            }

            &:nth-child(even) {
                .image {
                    order: 2;
                    margin-left: -70px;
                    margin-right: 0;
                }

                .text {
                    order: 1;
                    padding: 70px 180px 70px 120px;
                }
            }


            @media(max-width: 1199px) {

                margin: 110px 0;
                .image {
                    
                    top: -30px;
                    margin-right: -80px
                }

                .text {
                    padding: 60px 70px 60px 140px;

                    h3 {
                        font-size: 28px;
                    }

                }

                &:nth-child(even) {
                    .image {
                        margin-left: -70px;
                        margin-right: 0;
                    }

                    .text {
                        padding: 60px 130px 60px 70px;
                    }
                }

            }
            @media(max-width: 991px) {
                margin: 90px 0;
                .image {
                    min-height: 300px;
                    flex-basis: 45%;
                    max-width: 45%;
                    top: -30px;
                    margin-right: -80px
                }

                .text {
                    padding: 50px 70px 50px 120px;
                    flex-basis: 70%;
                    max-width: 70%;
                    h3 {
                        font-size: 25px;
                    }

                }

                &:nth-child(even) {
                    .image {
                        margin-left: -70px;
                        margin-right: 0;
                    }

                    .text {
                        padding: 50px 110px 50px 70px;
                    }
                }

            }
            @media(max-width: 767px) {
                margin: 80px 0;
                .image {
                    min-height: 200px;
                    flex-basis: 40%;
                    max-width: 40%;
                    top: -30px;
                    margin-right: -60px
                }

                .text {
                    padding: 40px 50px 40px 80px;
                    flex-basis: 70%;
                    max-width: 70%;
                    .label{
                        margin-bottom: 10px;
                        font-size: 11px;
                    }
                    h3 {
                        font-size: 22px;
                        margin-bottom: 10px;
                    }
                    .preview {
                        line-height: 1.4;
                    }
                    .button{
                        margin-bottom:0;
                    }

                }

                &:nth-child(even) {
                    .image {
                        margin-left: -70px;
                        margin-right: 0;
                    }

                    .text {
                        padding: 40px 80px 40px 50px;

                        
                    }
                }
                

            }
            @media(max-width: 575px) {
                flex-wrap: wrap;
                margin: 0 -15px 0; 
                .image {
                    min-height: 55vw;
                    flex-basis: 100%;
                    max-width: 100%;
                    top: 0px;
                    margin-right: 0;
                    z-index: 1;
                }

                .text {
                    padding: 25px 30px;
                    flex-basis: calc(100% - 30px);
                    max-width: calc(100% - 30px);
                    margin: 0 auto;
                    top: -40px;
                    z-index: 2;
                    position: relative;
                    
                    h3 {
                        font-size: 17px;
                    }
                    

                }

                &:nth-child(even) {
                    margin-top: 0;
                    .image {
                        margin-left: 0;
                        margin-right: 0;
                        order: 1;
                    }

                    .text {
                        padding: 25px 30px; 
                        order: 2;
                        
                    }
                }

                &:first-child {
                    margin-top: 40px;
                }

                &:last-child{
                    margin-bottom: -40px!important;
                }
            }
        }
    }

    &.pull-up {
        margin-top: -150px;
        margin-bottom: -150px;
        @media (max-width: 1199px) {
            margin-bottom: -140px;
        }
        @media (max-width: 991px) {
            margin-bottom: -100px;
        }
    
        @media (max-width: 767px) {
            margin-top: -100px;
            margin-bottom: -50px;
        }
        
    }


    ul {
        list-style: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 15px;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: calc(50% - 6px);
                width: 4px;
                height: 12px;
                background: $orange;
            }
        }
    }

    

    .content{
        padding: 70px;
        background: #fff;
        box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);



        @media (max-width: 1199px) {
            padding: 60px;
        }
    
        @media (max-width: 767px) {
            padding: 50px;
        }
        @media (max-width: 575px) {
            padding: 20px;
        }
        .main-image{
            box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
            background-size: cover;
            background-position: 50% 50%;
            margin-bottom: 40px;
            height: 300px;
            flex-basis: 100%;
            max-width: 100%;
            @media (max-width: 991px) {
                height: auto;
            }

            @media (max-width: 767px) {
                height: 250px;
            }
            @media (max-width: 575px) {
                height: 50vw;
            }
            
        }

        .contact-widget{
            box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
            background: $purple;
            color: #fff;
            margin-bottom: 40px;
            font-size: 22px;
            line-height: 1.7;
            padding: 40px;
            flex-basis: 100%;
            max-width: 100%;;
            @media (max-width: 991px) {
                font-size: 18px;
                padding: 30px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
                padding: 30px;
                line-height: 1.5;
            }
        
           
        }

        .desc{
            margin-bottom: 40px;

            h3{
                font-size: 1.75rem;
                font-weight: 600;
                @media(max-width: 991px){
                    font-size: 1.5rem;
                }
                @media(max-width: 767px){
                    font-size: 1.4rem;
                }
                @media(max-width: 575px){
                    font-size: 1.3rem;
                }
            }

            .table thead th{
                background: $purple;
                color: #fff!important;
            }
        }

        .gallery{

            display: flex;
            flex-wrap: wrap;
            a{
                flex-basis: 25%;
                max-width: 25%;
                background-size: cover;
                background-position: 50% 50%;
                height: 200px;
                position: relative;
                overflow: hidden;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: inherit;
                    transition: inherit;
                    transition: all 2s;
                }

                &:hover{
                    opacity: 0.6;
                }

                &:hover:before, &:focus:before,{
                    transform: scale(1.2);
                }
            }

        }

    }
    &.contact{

        #contact-form-2{

            
            h4{
                margin-bottom: 20px;
                @media (max-width: 991px) {
                    font-size: 20px;
                }
            }

            .inner{
                @media (max-width: 991px) {
                    margin-bottom: 40px;
                }
            }

           
            label{
                font-size: 21px;
                @media (max-width: 1199px) {
                    font-size: 19px;
                }
                @media (max-width: 991px) {
                    font-size: 17px;
                }
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .form-control{
                background: #F2F2F3;
                border-radius: 0px;
                box-sizing: border-box;
                border: 0px;
                box-shadow: none;
                outline: none;
                padding: 20px;
                font-size: 16px;
                color: $purple;
                &:hover, &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }

            .fancy-label{
                margin-bottom: 20px;
                label{
                    position: absolute;
                    top: 13px;

                    font-size: 15px;
                    font-weight: 500;
                    pointer-events: none;
                    left: 20px;
                    transition: .3s;
                    
                }

                input:focus ~label,
                    input:valid ~label
                    {
                    left: 20px;
                    top: 2px;
                    color: #afafaf;
                    
                    font-size: 12px;
                }
                
                .form-control{
                    border: 0;
                    border-radius: 0;
                    padding: 20px 20px 5px;
                    margin-bottom: 0;
                    box-sizing: border-box;
                    box-shadow: none;
                    outline: none;
                    font-size: 16px;
                    height: auto;
                    
                    &:hover, &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .form-group{
                position: relative;
                margin-bottom: 15px;


            }

            #contact-2-submit{
                white-space: nowrap;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 1px;
                margin-top: 30px;
            }


            .error{
                margin-top: 10px;
                margin-bottom: -20px;
                font-style: italic;
            }

            .success{
                margin-top: 10px;
                margin-bottom: -20px;
                h3{
                    color: $green;
                    font-size: 20px;
                }
            }




        }


        /*the container must be positioned relative:*/
        .custom-select {
            position: relative;
            display: block;
            padding: 0;  
            height: auto;
            border: 0px;
            border-radius: 0px;
            background: none;
            margin-bottom: 15px;
        }
        .custom-select select {
            display: none; /*hide original SELECT element:*/
        }
        .select-selected {
            background-color: #F2F2F3;
            
        }
        /*style the arrow inside the select element:*/
        .select-selected:after {
            position: absolute;
            content: "\f078";
            top: 11px;
            right: 14px;
            font-family: 'FontAwesome';

        }
        /*point the arrow upwards when the select box is open (active):*/
        .select-selected.select-arrow-active:after {
            content: "\f077";
        }
        /*style the items (options), including the selected item:*/
        .select-items div,.select-selected {
            font-size: 15px;
            color: #fff;
            padding: 12px 40px 12px 20px;
            cursor: pointer;
            font-weight: 500;
        }

        .select-selected {
            color: $purple;
        }
        /*style items (options):*/
        .select-items {
            position: absolute;
             background-color: $purple;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 99;
            color: #fff;
        }
        /*hide the items when the select box is closed:*/
        .select-hide {
            display: none;
        }
        .select-items div:hover, .same-as-selected {
            background-color: rgba(255, 255,255, 0.1);
        }


    }



  
}