$darkblue: #626381!default;
$gray: #666!default;
$purple: #626381;
$red: #eb595e;
$darkgrey: #3e323b;
$green: #9bbc23;
$pink: #e9e1e8;
$darkpink: #a0859b;
$orange: #cc8b6f;
   
@import url("https://use.typekit.net/cxc2yoq.css");
@import "simplelightbox"; 
@import "header";
@import "global";
@import "home";
@import "page";
@import "footer";        


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome and Opera */
} 

body{

    
font-family: semplicitapro, sans-serif;

font-weight: 400;
 
font-style: normal;
    b, strong {
        font-weight: bold;
    }


    
    line-height: 1.5;
    color: $purple;
    position: relative;
    background: #fff;
    z-index: 2;
    .wrapper{
        transition: 0.2s transform ease-in;
        background: #fff;
        position: relative;
        z-index: 2;
    }


    &.menu-open{

        .wrapper{
            transform: translateX(-220px);
            -webkit-transform: translateX(-220px);
            
        }



        #menu{
            opacity: 1;

            li{
                -webkit-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);

                opacity: 1;
            }
        }

    }

}

.row{
    &:before, &:after{
        display: flex;
    }
}




.transition{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;    
    -o-transition: all 0.3s; 
    transition: all 0.3s;
}

a{
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;    
    -o-transition: all 0.3s; 
    transition: all 0.3s;

    color: $red;

    &:hover{
        color: $purple ;
        text-decoration: none;
    }
}

.font-green{
    color: $green;
}
.font-darkgrey{
    color: $darkgrey;
}
.font-darkpink{
    color: $darkpink;
}

.font-gotham{
    font-family: 'Gotham';
}

.font-bold{
    font-weight: bold!important;
}
.font-normal{
    font-weight: 400!important;
}
.font-light{
    font-weight: 300!important;

    &.font-xs-regular{
        @media (max-width: 575px) {

            font-weight: 400!important;
        }
    }
}
.font-thin{
    font-weight: 100!important;
}
.font-medium{
    font-weight: 500!important;
}
.font-black{
    font-weight: 900!important;

}

.font-italic{
    font-style: italic;
}

.font-ultra{
    font-family: 'Gotham Ultra';
    font-weight: normal!important;

    &.font-xs-black{
        @media (max-width: 575px) {
            font-family: 'Gotham';
            font-weight: 900!important;
        }
    }
}


.font-eurostile{
    font-family: Eurostile;
}

.font-mont{
    font-family: Montserrat;
}

.button{



    padding: 12px 18px;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;

    

    i{
        margin-left: 10px; 
    }
    
    &.orange{
        color: #fff!important;
        background: $orange;
        &:hover, &:focus{
            background: darken($orange, 10%);  
        }
        
        

    }
   
}

.green-angle{
    position: relative;
    display: inline-flex;
    img{
        height: 59px;
        width: 59px;
        @media (max-width: 1199px) {
            height: 52px;
            width: 52px;
        }
        @media (max-width: 991px) {
            height: 38px;
            width: 38px;
        }
        @media (max-width: 767px) {
            height: 30px;
            width: 30px;
        }


    }
}

.main{
    ul{
        padding: 0; 
        list-style: none; 
        li{
            padding-left: 20px;
            position: relative;
            &:after{
                content:'\f111';
                font-family: 'FontAwesome';
                size: 10px;
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                width: 20px;
                display: block;
                font-size: 7px;
                line-height: 23px;
                color: $orange;
            }
        }
    }
}




.pagination{
    display: table;
    border: 1px solid #ccc;
    border-radius: 0;
    border-collapse: collapse;
    margin: 30px 0;

    a, strong{
        display: table-cell;
        padding: 5px 10px;
        color: #000;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ccc;
        &:hover{
            text-decoration: none;
        }
    }

    a{
        &:hover{
            color: $orange;
        }
    }

    strong{
        background: $orange;
        color: #fff;
    }

}

.mw100{
    max-width: 100%;
}

.page-header{
    background-color: $darkgrey;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    @media (max-width: 1199px) {
        min-height: 500px;
        padding-top: 100px;
        align-items: flex-start;
        embed{
            width: 600px;
        }
    }
    @media (max-width: 991px) {
        min-height: 400px;
        padding-top: 50px;
        align-items: flex-start;
        embed{
            width: 500px;
        }
    }
    @media (max-width: 767px) {
        min-height: 350px;
        padding-top: 70px;
        align-items: flex-start;
        embed{
            width: 400px;
        }
    }
    @media (max-width: 575px) {
        min-height: 300px;
        padding-top: 50px;
        embed{
            width: 320px;
        }
    }
}
 
.bottom-header{
    > .container{
        position: relative;
    }
    
    .inner{
        box-shadow: 0px 5px 50px rgba(0,0,0,0.1);
        padding: 50px 150px;
        position: relative;
        
        font-weight: 400;
        line-height: 1.8;
        background-color: #fff;
        font-size: 18px;
        h1{
            font-size: 34px;
            font-weight: bold;
        }

        .button{
            font-size: 11px;
        }
       
        @media (max-width: 1199px) {
            padding: 40px 100px;
            h1{
                font-size: 30px;
            }
    
        }
        @media (max-width: 991px) {
            padding: 30px 50px;
            font-size: 17px;
            h1{
                font-size: 28px;
            }
        }
        @media (max-width: 767px) {
            padding: 30px 30px;
            font-size: 16px;
            line-height: 1.5;
            h1{
                font-size: 25px;
            }
    
            
        }
        @media (max-width: 575px) {
            padding: 25px 15px;
            font-size: 15px;
            h1{
                font-size: 22px;
            }
    
           
        }

    }






}

.top-header{

    .inner{
        max-width: 70%;
        margin: 0 auto;
        @media (max-width: 991px) {
            max-width: 90%;
        }
        @media (max-width: 767px) {
            max-width: 100%;
        }

    }
            
    p{
        margin-bottom: 0;
    }
           
    padding: 30px 150px;
    position: relative;
    
    font-weight: 400;
    line-height: 1.8;
    background-color: #fff;
    font-size: 18px;
    h1{
        font-size: 34px;
        font-weight: 600;
    }

    
   
    @media (max-width: 1199px) {
        padding: 40px 100px;
        h1{
            font-size: 30px;
        }

    }
    @media (max-width: 991px) {
        padding: 30px 50px;
        font-size: 17px;
        h1{
            font-size: 28px;
        }
    }
    @media (max-width: 767px) {
        padding: 30px 30px;
        font-size: 16px;
        line-height: 1.5;
        h1{
            font-size: 25px;
        }

        
    }
    @media (max-width: 575px) {
        padding: 25px 15px;
        font-size: 15px;
        h1{
            font-size: 22px;
        }

       
    }





}

