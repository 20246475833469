 .menu-container {
     #menu {
         list-style: none;
         display: flex;
         justify-content: flex-end;
         align-items: center;
         margin-bottom: 0;
         padding-left: 0;

         li {
             display: block;
            cursor: pointer;
             a {
                 color: $purple;
                 padding: 8px 15px;
                 font-size: 16px;
                 font-weight: 600;
                 margin-left: 5px;

                 @media (max-width: 1199px) {
                     padding: 10px 12px;
                 }
             }

             &:hover,
             &:focus {
                 > a {
                     background: #f2f2f2;
                 }

                 .submenu{
                    opacity: 1;
                    visibility:visible;
                    
                 }

             }
             position: relative;
             .submenu{
                position: absolute;
                opacity: 0;
                visibility: hidden;
                top: 100%;
                left: 5px;
                width: 150px;
                padding: 15px;
                border: 1px solid #f2f2f2;
                background: #f2f2f2;
                transition: 0.3s all ease;
                ul{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li{
                        a{
                            margin: 0;
                            padding: 0;
                            display: block;
                            text-align: left;
                            color: $purple;    
                            padding: 5px 0;  
                            &:hover,
                            &:focus {
                                    color: $orange;      
                            }
                        
                        }
                    }
                }

                @media(max-width: 991px){
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                    width: 100%;
                    left: 0;
                    ul{
                        li{
                            a{
                                padding-left: 20px;
                            }
                        }
                    }
                    
                }
             }
         }


     }



     @media(max-width: 991px) {

         position: fixed;

         top: 0;
         right: 0;
         width: 220px;
         transform: translateX(220px);
         background: $purple;
         height: 100%;
         text-align: left;

         #menu {
             display: block;

             li {
                 a {
                     color: #fff;
                     margin-left: 0;
                     padding: 12px 20px;
                    
                     
                 }
                 &:hover,
                     &:focus {
                         a{
                            color: $purple;
                         }
                        
                     }
             }
         }

     }
 }



 #header {
     padding: 20px 0;
    position: relative;
    z-index: 10;;
     @media(max-width: 767px) {
        padding: 15px 0 15px;
     }
     @media(max-width: 575px) {
        padding: 15px 0 0px;
     }
     .logo{
        @media(max-width: 767px) {
            flex-basis: 170px;
            max-width: 170px;
        }
        
        a{
            img{
                max-width: 100%;
            }
        }
     }

     .top-bar {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         margin-bottom: 5px;

         @media(max-width: 991px) {
             margin-bottom: 0px;
         }
         @media(max-width: 575px) {
            margin-top: 15px;
            background: #f2f2f2;
         }

         a {
             display: inline-flex;
             margin-left: 20px;
             color: $purple;
             font-size: 16px;
         }

         .button {
             font-size: 11px;
             white-space: nowrap;
         }


     }

     .menu-toggle {
         margin-left: 10px;
         font-size: 14px;
         align-items: center;
         padding: 5px 10px;
         line-height: 1;
         background: #f2f2f2;
         cursor: pointer;

         i {
             font-size: 30px;
         }

         &:hover,
         &:focus {
             background: darken(#f2f2f2, 10%);
         }
     }


 }