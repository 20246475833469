#footer{
    position: relative;
    z-index: 1;
    overflow: hidden;
    .pink-footer{
        background-image: url('/img/testimonials_slide.jpg');
        background-size: cover;
        background-position: 50% 50%;

        .inner{
            font-size: 23px;
            padding: 230px 0;
            position: relative;
            z-index: 1;
            line-height: 1.2;
            
            @media(max-width:1199px) {
                padding: 200px 0;
            }
            @media(max-width:991px) {
                padding: 150px 0;
            }
            @media(max-width:767px) {
                padding: 100px 0;
            }
            #testimonials{
                padding: 0 80px;
                @media(max-width:767px) {
                    padding: 0 ;
                }
                .item{
                    font-size: 22px;
                    line-height: 1.4;
                    color: #fff;
                    font-weight: normal;
                    text-align: left!important;
                    @media (max-width: 991px) {
                        font-size: 18px;
                        line-height: 1.2;
                    }
                    @media (max-width: 767px) {
                       font-size: 16px;
                      
                       
                    }
                    @media (max-width: 767px) {
                        text-align: center!important;
                    }

                    img{
                        @media (max-width: 767px) {
                            width: 50%;
                            margin: 0 auto;
                            margin-bottom: 20px;
                         }
                    }

                    a{
                        color: #fffaba;
                    }
                    .bottom-line{
                        font-size: 16px;
                    }
                 
                }
             
                .owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span{
                    background: $orange;
                }

                .owl-dots .owl-dot span{
                    border-radius: 0;
                    background: #fff;
                    
                }

                .owl-prev, .owl-next{ 
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    top: calc(50% - 25px);
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $orange;
                    i{
                        font-size: 30px;
                    }

                    &:hover, &:focus{
                        background: darken($orange, 10%);
                    }
                }

                .owl-next{
                    
                    right: 0;
                    
                }
                .owl-prev{
                    left: 0;
                }
            }
        }
    }
    
   
    #book-now{

        position: relative;
        z-index: 10; 

        
        
        .extra-image{

            position: absolute;
            box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
    
            &.right{
                bottom: 0;
                width: 350px;
                right: 0;
                transform: translateX(150px); 
                z-index: 1;
            }
    
            &.left{
                top: 70px;
                left: -110px;
                width: 230px;
                @media(max-width: 1199px){
                    left: -60px;
                    width: 180px;
                }
            }
            
            @media(max-width: 991px) {
                display: none;
            }
        }
    


        padding: 60px;
        margin-top: -200px;

        @media(max-width: 991px) {
            padding:  0;
            margin-top: -100px;
        }
        @media(max-width: 767px) {
            margin-top: -50px;
            padding: 0;
        }


        .inner{
            padding: 30px 80px 60px;
            background: #fff;
            box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
            position: relative;
            z-index: 2;
            
            @media(max-width: 767px) {
                padding: 30px 30px 60px;
            }
            @media(max-width: 575px) {
                padding: 30px 15px 60px;
            }
            .top{
                text-align: center;
                max-width: 60%;
                @media(max-width: 991px) {
                    max-width: 100%;
                }
                
                > img{
                    margin-bottom: 15px;
                    @media(max-width: 767px) {
                        width: 50%;
                    }
                }
                margin: 0px auto;
                font-weight: normal;
                font-size: 20px;
                line-height: 1.6;
                @media(max-width: 767px) {
                    font-size: 16px;
                    line-height: 1.4;
                }
                @media(max-width: 575px) {
                    font-size: 14px;
                    line-height: 1.2;
                }
                

                a{
                    color: $purple;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    img{
                        margin-right: 10px;
                    }

                    &:hover, &:focus{
                        color: $orange;
                    }
                }
                h4{
                    font-size: 24px;
                    margin: 40px 0 30px;
                    font-weight: 500;
                    @media(max-width: 767px) {
                        font-size: 20px;
                        margin: 30px 0 20px;
                    }
                    @media(max-width: 575px) {
                        font-size: 18px;
                        line-height: 1.2;
                        margin: 20px 0 20px;
                    }

                }
            }

            #contact-form{

                
                h4{
                    margin-bottom: 20px;
                    @media (max-width: 991px) {
                        font-size: 20px;
                    }
                }
    
                .inner{
                    @media (max-width: 991px) {
                        margin-bottom: 40px;
                    }
                }
    
               
               
                .radio-input{
                    padding: 0 20px;
                    margin-bottom: 20px;
                    .form-check-inline{
                        margin-left: 10px;
                        text-align: center;
                    }
                }
    
                .form-control{
                    background: #F2F2F3;
                    border-radius: 0px;
                    box-sizing: border-box;
                    border: 0px;
                    box-shadow: none;
                    outline: none;
                    padding: 20px;
                    font-size: 16px;
                    color: $purple;
                    &:hover, &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
    
                .fancy-label{
                    margin-bottom: 20px;
                    label{
                        position: absolute;
                        top: 13px;

                        font-size: 15px;
                        font-weight: 500;
                        pointer-events: none;
                        left: 20px;
                        transition: .3s;
                        
                    }
    
                    input:focus ~label,
                        input:valid ~label
                        {
                        left: 20px;
                        top: 2px;
                        color: #afafaf;
                        
                        font-size: 12px;
                    }
                    
                    .form-control{
                        border: 0;
                        border-radius: 0;
                        padding: 20px 20px 5px;
                        margin-bottom: 0;
                        box-sizing: border-box;
                        box-shadow: none;
                        outline: none;
                        font-size: 16px;
                        height: auto;
                        
                        &:hover, &:focus{
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                .form-group{
                    position: relative;
                    margin-bottom: 15px;
    
    
                }

                #contact-submit{
                    white-space: nowrap;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 1px;
                }
    
                .personal-data{
                    h4{
                        margin-top: 40px;
                        margin-bottom: 3px;
                    }
                    small{
                        max-width: 500px;
                        line-height: 1.4;
                        display: block;
                        font-size: 13px;
                    }
                    @media(max-width: 575px){
                        margin-bottom: 20px;
                    }
                }
    
                .error{
                    margin-top: 10px;
                    margin-bottom: -20px;
                    font-style: italic;
                }
    
                .success{
                    margin-top: 10px;
                    margin-bottom: -20px;
                    h3{
                        color: $green;
                    }
                }
    
    
    
    
            }
    
    
            /*the container must be positioned relative:*/
            .custom-select {
                position: relative;
                display: block;
                padding: 0; 
                height: auto;
                border: 0px;
                border-radius: 0px;
                background: none;
                margin-bottom: 15px;
            }
            .custom-select select {
                display: none; /*hide original SELECT element:*/
            }
            .select-selected {
                background-color: #F2F2F3;
                
            }
            /*style the arrow inside the select element:*/
            .select-selected:after {
                position: absolute;
                content: "\f078";
                top: 11px;
                right: 14px;
                font-family: 'FontAwesome';
    
            }
            /*point the arrow upwards when the select box is open (active):*/
            .select-selected.select-arrow-active:after {
                content: "\f077";
            }
            /*style the items (options), including the selected item:*/
            .select-items div,.select-selected {
                font-size: 15px;
                color: #fff;
                padding: 12px 40px 12px 20px;
                cursor: pointer;
                font-weight: 500;
            }

            .select-selected {
                color: $purple;
            }
            /*style items (options):*/
            .select-items {
                position: absolute;
                 background-color: $purple;
                top: 100%;
                left: 0;
                right: 0;
                z-index: 99;
                color: #fff;
            }
            /*hide the items when the select box is closed:*/
            .select-hide {
                display: none;
            }
            .select-items div:hover, .same-as-selected {
                background-color: rgba(255, 255,255, 0.1);
            }
    
        }
    }
    
    .bottom-footer{
        padding: 30px 20px 40px;
        background: $purple;
        color: #fff;


       
        .social{
            
            text-align: right;
            @media(max-width: 767px){
                text-align: center;
                margin-bottom: 20px;
            }
            a {
                display: inline-flex;
                margin-left: 20px;
                color: #fff;
                font-size: 16px;
                @media(max-width: 767px){
                    margin: 0 10px;
                }
            }
   
        }

        #footer-menu{  
            margin: 20px 0;
            a{
                font-size: 14px;
                padding: 0 15px;
                font-weight: 500;
            }
            @media(max-width: 767px){
                li{
                    display: block!important;
                    text-align: center;
                    margin-right: 0!important;
                    margin-bottom: 5px;
                }
            }
        }

        font-size: 14px;
        a{
            color: #fff;
            
            &:hover, &:focus{
                color: $orange;
            }
        }
        
        .copyright{
            font-size: 11px;
            color: #ccc;
            font-weight: 500;
        }
       
    }


}
